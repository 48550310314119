let url = "";
let isInProd = false;
let url_ai = "";

if (process.env.NODE_ENV === 'development') {
	url = process.env.REACT_APP_URL;
	url_ai = process.env.REACT_APP_URL_AI;
} else if (process.env.REACT_APP_STAGE === 'homolog') {
	url = "https://dev.api.cashbarber.com.br";
	url_ai = "https://api-ai.cashbarber.com.br";
} else {
	url = "https://api.cashbarber.com.br";
	isInProd = true;
	url_ai = "https://api-ai.cashbarber.com.br";
}

export const Constants = {
	url: url,
	url_ai: url_ai,
	baseUrl: url + "/api/admin",
	isInProd
};
