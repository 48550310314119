import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const RecenciaFrequenciaPage = lazy(() =>
	import("./recencia-frequencia/RecenciaFrequenciaPage")
);

const GrupoPermissaoPage = lazy(() =>
	import("./grupo-permissao/GrupoPermissaoPage")
);

const UsuarioPage = lazy(() =>
	import("./usuario/UsuarioPage")
);

const UsuarioPotePage = lazy(() =>
	import("./usuario-pote/UsuarioPotePage")
);

const GuruTokenPage = lazy(() =>
	import("./guru-token/GuruTokenPage")
);

const GuruClientePage = lazy(() =>
	import("./guru-cliente/GuruClientePage")
);

const GuruWebHoookErrorLogPage = lazy(() =>
	import("./guru-webhook-error-log/GuruWebHookErrorLogPage")
);

const EmpresaPage = lazy(() =>
	import("./empresa/EmpresaPage")
);

const RelatorioPage = lazy(() =>
	import("./relatorio/RelatorioPage")
);

const SuportePage = lazy(() =>
	import("./suporte/SuportePage")
);

const ImportacaoPage = lazy(() =>
	import("./importacao/ImportacaoPage")
);

const ComunicadoPage = lazy(() =>
	import("./comunicado/ComunicadoPage")
);

const CustomerSuccessPage = lazy(() =>
	import("./customer-success/CustomerSuccessPage")
);

const NegociacoesDomPage = lazy(() => 
	import("./negociacoes-dom/NegociacoesDomPage")
);

const BannerPage = lazy(() =>
	import("./banner/BannerPage")
);

const CategoriasEscolaCashbarber = lazy(() =>
	import("./escola-cashbarber/categorias/CategoriaEscolaCashbarberPage")
);

const ConteudosEscolaCashbarber = lazy(() =>
	import("./escola-cashbarber/conteudos/ConteudoEscolaCashbarberPage")
);

const GuruRelatorioPage = lazy(() =>
	import("./guru-relatorio/GuruRelatorioPage")
);

const ConfiguracaoDomPage = lazy(() =>
	import("./config-dom/ConfiguracaoDomPage")
);

export default function HomePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					<Redirect exact from="/" to="/dashboard" />
				}

				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/grupo-permissao" component={GrupoPermissaoPage} />
				<Route path="/pote" component={UsuarioPotePage} />
				<Route path="/guru-token" component={GuruTokenPage} />
				<Route path="/guru-cliente" component={GuruClientePage} />
				<Route path="/guru-webhook-error-log" component={GuruWebHoookErrorLogPage} />
				<Route path="/usuario" component={UsuarioPage} />
				<Route path="/empresa" component={EmpresaPage} />
				<Route path="/relatorio" component={RelatorioPage} />
				<Route path="/suporte" component={SuportePage} />
				<Route path="/importacao" component={ImportacaoPage} />
				<Route path="/comunicado" component={ComunicadoPage} />
				<Route path="/customer-success" component={CustomerSuccessPage} />
				<Route path="/recencia-frequencia" component={RecenciaFrequenciaPage} />
				<Route path="/dom-pagamentos/config-dom-pagamentos" component={ConfiguracaoDomPage} />
				<Route path="/dom-pagamentos/negociacoes" component={NegociacoesDomPage} />
				<Route path="/banner" component={BannerPage} />
				<Route path="/escola-cashbarber/categorias" component={CategoriasEscolaCashbarber} />
				<Route path="/escola-cashbarber/conteudos" component={ConteudosEscolaCashbarber} />
				<Route path="/guru-relatorio" component={GuruRelatorioPage} />
				<Route path="/config-dom-pagamentos" component={ConfiguracaoDomPage} />

				<Redirect to="/error/error-v6" />
			</Switch >
		</Suspense >
	);
}
